import React, { Component } from "react";
import Link from "next/link";
import { connect } from "react-redux";
import { showModal, hideModal } from "../_actions";

class RegisterButton extends Component {
  constructor(props) {
    super(props);

    this.openRegistrationModal = this.openRegistrationModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openRegistrationModal() {
    this.props.showModal(
      {
        open: true,
        title: "Sign Up",
        message: "",
        size: "lg",
        closeModal: this.closeModal,
      },
      "registration"
    );
  }
  closeModal() {
    this.props.hideModal();
  }
  render() {
    return (
      <a onClick={this.openRegistrationModal} style={{ marginLeft: "5px" }}>
        Sign Up
      </a>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(null, mapDispatchToProps)(RegisterButton);
