import React from "react";
import Link from "next/link";
import { Nav, Navbar } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { home } from "react-icons-kit/fa/";
import styles from "../styles/Home.module.css";
import Image from 'next/image'

function MainMenu({ displayOn }) {
  return (
    <Navbar collapseOnSelect expand="lg" id={styles.menu}>
      {displayOn !== "landing" && (
        <Navbar.Brand className={styles.navbarBrand}>
          <Link href="/sell">
            <a>
              <span style={{ color: "#FFF" }}>
                <Icon size={32} icon={home} />
              </span>
            </a>
          </Link>
        </Navbar.Brand>
      )}
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className={`mr-auto ${styles.navbarNav}`}>
          <ul className="m-0 p-0 d-flex align-items-center gap-2">
            <li>
              <Link href="/sell-used-mobile-phones">
                <a className={styles.nav_item}>
                  <i className="mnusvg">
                    <svg
                      width="13"
                      height="22"
                      viewBox="0 0 13 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.1131 2.76727C12.1131 1.94026 11.4433 1.27042 10.6163 1.27042C8.46755 1.27042 4.53231 1.27042 2.38358 1.27042C1.55656 1.27042 0.886719 1.94026 0.886719 2.76727V19.2327C0.886719 20.0597 1.55656 20.7296 2.38358 20.7296H10.6163C11.4433 20.7296 12.1131 20.0597 12.1131 19.2327C12.1131 15.6081 12.1131 6.39191 12.1131 2.76727Z"
                        fill="#2DBBFF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.70613 13.6397C5.56018 13.7856 5.32293 13.7856 5.17699 13.6397C5.03104 13.4938 5.03104 13.2565 5.17699 13.1106L8.35257 9.93498C8.49851 9.78904 8.73576 9.78904 8.88171 9.93498C9.02765 10.0809 9.02765 10.3182 8.88171 10.4641L5.70613 13.6397ZM5.0258 12.2035C4.87986 12.3494 4.64261 12.3494 4.49666 12.2035C4.35072 12.0575 4.35072 11.8203 4.49666 11.6743L6.91633 9.25466C7.06228 9.10872 7.29878 9.10872 7.44547 9.25466C7.59142 9.4006 7.59142 9.63786 7.44547 9.7838L5.0258 12.2035Z"
                        fill="#8EDAFF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.00312 3.14149C4.79655 3.14149 4.62891 2.97385 4.62891 2.76728C4.62891 2.56071 4.79655 2.39307 5.00312 2.39307H7.99683C8.2034 2.39307 8.37105 2.56071 8.37105 2.76728C8.37105 2.97385 8.2034 3.14149 7.99683 3.14149H5.00312Z"
                        fill="#707070"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.886719 17.2127H8.1098C8.89191 17.2127 9.52583 16.5788 9.52583 15.7967V1.27042H10.6163C11.4433 1.27042 12.1131 1.94026 12.1131 2.76727V19.2327C12.1131 20.0597 11.4433 20.7296 10.6163 20.7296H2.38358C1.55656 20.7296 0.886719 20.0597 0.886719 19.2327V17.2127Z"
                        fill="#0AB0FF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.4875 2.76728V19.2327C12.4875 20.2663 11.6501 21.1038 10.6165 21.1038H2.38377C1.35019 21.1038 0.512695 20.2663 0.512695 19.2327V2.76728C0.512695 1.7337 1.35019 0.89621 2.38377 0.89621H10.6165C11.6501 0.89621 12.4875 1.7337 12.4875 2.76728ZM11.7391 2.76728C11.7391 2.14758 11.2362 1.64464 10.6165 1.64464H2.38377C1.76407 1.64464 1.26112 2.14758 1.26112 2.76728V19.2327C1.26112 19.8524 1.76407 20.3553 2.38377 20.3553H10.6165C11.2362 20.3553 11.7391 19.8524 11.7391 19.2327V2.76728Z"
                        fill="#707070"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.52602 0.89621H10.6165C11.6501 0.89621 12.4875 1.7337 12.4875 2.76728V19.2327C12.4875 20.2663 11.6501 21.1038 10.6165 21.1038H2.38377C1.35019 21.1038 0.512695 20.2663 0.512695 19.2327V17.2127H1.26112V19.2327C1.26112 19.8524 1.76407 20.3553 2.38377 20.3553H10.6165C11.2362 20.3553 11.7391 19.8524 11.7391 19.2327V2.76728C11.7391 2.14758 11.2362 1.64464 10.6165 1.64464H9.52602V0.89621Z"
                        fill="#595959"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.4875 2.76728V19.2327C12.4875 20.2663 11.6501 21.1038 10.6165 21.1038H2.38377C1.35019 21.1038 0.512695 20.2663 0.512695 19.2327V2.76728C0.512695 1.7337 1.35019 0.89621 2.38377 0.89621H10.6165C11.6501 0.89621 12.4875 1.7337 12.4875 2.76728ZM11.7391 2.76728C11.7391 2.14758 11.2362 1.64464 10.6165 1.64464H2.38377C1.76407 1.64464 1.26112 2.14758 1.26112 2.76728V19.2327C1.26112 19.8524 1.76407 20.3553 2.38377 20.3553H10.6165C11.2362 20.3553 11.7391 19.8524 11.7391 19.2327V2.76728ZM5.00327 3.14149C4.7967 3.14149 4.62905 2.97385 4.62905 2.76728C4.62905 2.56071 4.7967 2.39307 5.00327 2.39307H7.99698C8.20355 2.39307 8.37119 2.56071 8.37119 2.76728C8.37119 2.97385 8.20355 3.14149 7.99698 3.14149H5.00327Z"
                        fill="#272A3B"
                      />
                    </svg>
                  </i>
                  <span>Sell Mobile</span>
                  <i className="mnuarw">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.26956 5.625C5.15767 5.625 5.04567 5.58223 4.96025 5.49681L0.585246 1.12181C0.414293 0.950856 0.414293 0.674028 0.585246 0.503184C0.756199 0.332341 1.03303 0.332231 1.20387 0.503184L5.26956 4.56887L9.33525 0.503184C9.5062 0.332231 9.78303 0.332231 9.95387 0.503184C10.1247 0.674137 10.1248 0.950965 9.95387 1.12181L5.57887 5.49681C5.49345 5.58223 5.38145 5.625 5.26956 5.625Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </Link>
              <ul className="submnu">
                <li>
                  <Link href="/sell-used-mobile-phones/apple-iphone">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/apple-ico.svg"
                          width={20}
                          height={20}
                          alt="Apple iPhone"
                        />
                      </i>
                      <span>Apple iPhone</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-mobile-phones/samsung">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/samsung-ico.svg"
                          width={20}
                          height={20}
                          alt="Samsung"
                        />
                      </i>
                      <span>Samsung</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-mobile-phones/xiaomi">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/xiaomi-ico.svg"
                          width={20}
                          height={20}
                          alt="Xiaomi"
                        />
                      </i>
                      <span>Xiaomi</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-mobile-phones/motorola">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Motorola-ico.svg"
                          width={20}
                          height={20}
                          alt="Motorola"
                        />
                      </i>
                      <span>Motorola</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-mobile-phones/realme">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Realme-ico.svg"
                          width={20}
                          height={20}
                          alt="Realme"
                        />
                      </i>
                      <span>Realme</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-mobile-phones/google">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Google-Pixel-ico.svg"
                          width={20}
                          height={20}
                          alt="Google Pixel"
                        />
                      </i>
                      <span>Google Pixel</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-mobile-phones/oneplus">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Oneplus-ico.svg"
                          width={20}
                          height={20}
                          alt="Oneplus"
                        />
                      </i>
                      <span>Oneplus</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-mobile-phones/others">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/others-ico.svg"
                          width={20}
                          height={20}
                          alt="Others"
                        />
                      </i>
                      <span>Others</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="/sell-used-laptops">
                <a className={styles.nav_item}>
                  <i className="mnusvg">
                    <svg
                      width="24"
                      height="16"
                      viewBox="0 0 24 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.2903 1.3555V13.7694H14.1001L13.76 15.0932H10.2402L9.90005 13.7694H1.70996V1.3555C1.71109 0.784386 2.17376 0.321713 2.74487 0.320588H21.255C21.8259 0.319801 22.2894 0.781967 22.2902 1.35286C22.2903 1.35376 22.2903 1.35466 22.2903 1.3555Z"
                        fill="#62667C"
                      />
                      <path
                        d="M10.1401 14.2693H1.70996V13.7694H9.90011L10.1401 14.2693Z"
                        fill="#62667C"
                      />
                      <path
                        d="M22.2905 13.7694V14.2693H13.8604L14.1003 13.7694H22.2905Z"
                        fill="#62667C"
                      />
                      <path
                        d="M21.2252 1.58644V12.7046H2.77539V1.58644C2.77539 1.47507 2.86566 1.3848 2.97703 1.3848H21.0236C21.135 1.3848 21.2252 1.47507 21.2252 1.58644Z"
                        fill="#69B0EE"
                      />
                      <path
                        d="M24 14.1045V14.4344C24 15.122 23.4426 15.6795 22.7549 15.6795H1.2451C0.557446 15.6794 0 15.122 0 14.4344V14.1045C0 13.9194 0.150062 13.7694 0.335109 13.7694H9.9L10.2401 14.4844H13.7599L14.1001 13.7694H23.6649C23.8499 13.7694 24 13.9194 24 14.1045Z"
                        fill="#525666"
                      />
                      <g opacity="0.5">
                        <path
                          d="M6.32578 12.7046H5.77148L13.9666 1.3848H14.5209L6.32578 12.7046Z"
                          fill="#EEEFEE"
                        />
                        <path
                          d="M10.0336 12.7046H7.82031L16.0154 1.3848H18.2287L10.0336 12.7046Z"
                          fill="#EEEFEE"
                        />
                      </g>
                    </svg>
                  </i>
                  <span>Sell Laptop</span>
                  <i className="mnuarw">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.26956 5.625C5.15767 5.625 5.04567 5.58223 4.96025 5.49681L0.585246 1.12181C0.414293 0.950856 0.414293 0.674028 0.585246 0.503184C0.756199 0.332341 1.03303 0.332231 1.20387 0.503184L5.26956 4.56887L9.33525 0.503184C9.5062 0.332231 9.78303 0.332231 9.95387 0.503184C10.1247 0.674137 10.1248 0.950965 9.95387 1.12181L5.57887 5.49681C5.49345 5.58223 5.38145 5.625 5.26956 5.625Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </Link>
              <ul className="submnu">
                <li>
                  <Link href="/sell-used-laptops/other-laptop">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/windows-ico.svg"
                          width={20}
                          height={20}
                          alt="Windows"
                        />
                      </i>
                      <span>Windows</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-laptops/apple-macbook">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/apple-ico.svg"
                          width={20}
                          height={20}
                          alt="Macbook"
                        />
                      </i>
                      <span>Macbook</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="/sell-used-tablets">
                <a className={styles.nav_item}>
                  <i className="mnusvg">
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.9502 0.793704C14.7722 0.690622 14.5663 0.632294 14.3458 0.632294H1.65408C0.986481 0.632294 0.445312 1.17346 0.445312 1.84102V18.1589C0.445312 18.3791 0.504044 18.5855 0.606832 18.7633L1.65404 19.0701H14.3483C14.8535 19.0701 15.2631 18.6605 15.2631 18.1552V1.84102L14.9502 0.793704Z"
                        fill="#615260"
                      />
                      <path
                        d="M13.7251 17.3964L12.4804 18.159H9.20852L2.2583 16.0731V2.51831L3.16485 1.84102H10.6773L13.7251 2.48256V17.3964Z"
                        fill="#FFF4D6"
                      />
                      <path
                        d="M12.2304 17.5546H3.76921C2.93475 17.5546 2.2583 16.8782 2.2583 16.0437V3.95633C2.2583 3.12187 2.93475 2.44542 3.76921 2.44542H12.2304C13.0648 2.44542 13.7413 3.12187 13.7413 3.95633V16.0437C13.7413 16.8781 13.0648 17.5546 12.2304 17.5546Z"
                        fill="white"
                      />
                      <path
                        d="M14.9502 0.793701V18.159C14.9502 18.4928 14.6796 18.7633 14.3459 18.7633H0.606934C0.816316 19.1231 1.20786 19.3677 1.65414 19.3677H14.3459C15.0134 19.3677 15.5546 18.8265 15.5546 18.159V1.84102C15.5546 1.39477 15.31 1.00308 14.9502 0.793701Z"
                        fill="#4E3D4D"
                      />
                      <path
                        d="M2.9634 2.67797C3.01583 2.36851 3.08723 2.0881 3.16521 1.84106H1.95648C1.78958 1.84106 1.6543 1.97635 1.6543 2.14325V17.8568C1.6543 18.0237 1.78958 18.159 1.95648 18.159H9.20891C9.2541 17.9744 9.29564 17.7723 9.32927 17.5546L4.10483 15.179L2.53117 3.98466L2.9634 2.67797Z"
                        fill="#FF5F7A"
                      />
                      <path
                        d="M14.0434 1.84102H10.6772C10.6063 1.92613 10.441 2.13497 10.2515 2.44527L13.5105 11.9959L13.1529 16.5023L12.5335 17.5233C12.5269 17.7313 12.5099 17.9438 12.4803 18.159H14.0434C14.2103 18.159 14.3456 18.0237 14.3456 17.8568V2.1432C14.3456 1.9763 14.2103 1.84102 14.0434 1.84102Z"
                        fill="#DFBDF0"
                      />
                      <path
                        d="M6.09196 9.88883C2.97665 7.07976 2.66949 4.41831 2.96304 2.67796C2.54087 2.94593 2.2583 3.41929 2.2583 3.95633V16.0437C2.2583 16.8782 2.93475 17.5546 3.76921 17.5546L9.32887 17.5545C9.61038 15.7347 9.34307 12.8204 6.09196 9.88883Z"
                        fill="#FF7E92"
                      />
                      <path
                        d="M12.2304 2.44538L10.2515 2.44524C9.44802 3.76015 8.20855 6.89804 11.8831 10.1604C13.7351 11.8046 13.1577 13.0741 12.5384 13.7637C12.1855 14.1565 12.0806 14.7107 12.2289 15.2222C12.3982 15.806 12.5624 16.6223 12.5336 17.5233C13.2244 17.3842 13.7413 16.7754 13.7413 16.0437V3.95633C13.7413 3.12187 13.0648 2.44538 12.2304 2.44538Z"
                        fill="#F2D1FF"
                      />
                    </svg>
                  </i>
                  <span>Sell Tablet</span>
                  <i className="mnuarw">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.26956 5.625C5.15767 5.625 5.04567 5.58223 4.96025 5.49681L0.585246 1.12181C0.414293 0.950856 0.414293 0.674028 0.585246 0.503184C0.756199 0.332341 1.03303 0.332231 1.20387 0.503184L5.26956 4.56887L9.33525 0.503184C9.5062 0.332231 9.78303 0.332231 9.95387 0.503184C10.1247 0.674137 10.1248 0.950965 9.95387 1.12181L5.57887 5.49681C5.49345 5.58223 5.38145 5.625 5.26956 5.625Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </Link>
              <ul className="submnu">
                <li>
                  <Link href="/sell-used-tablets/apple-ipad">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Apple-iPad.svg"
                          width={20}
                          height={20}
                          alt="Apple iPad"
                        />
                      </i>
                      <span>Apple iPad</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-tablets/samsung">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/samsung-ico.svg"
                          width={20}
                          height={20}
                          alt="Samsung"
                        />
                      </i>
                      <span>Samsung</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-tablets/others">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/others-ico.svg"
                          width={20}
                          height={20}
                          alt="Others"
                        />
                      </i>
                      <span>Others</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="/sell-used-othergadgets">
                <a className={styles.nav_item}>
                  <i className="mnusvg">
                    <svg
                      width="18"
                      height="26"
                      viewBox="0 0 18 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7758 6.10176C13.0068 5.76627 12.6864 3.76088 12.4942 1.96659C12.3924 1.322 11.8345 1.12975 10.9788 1.06567C10.1194 0.997816 9.46348 0.96389 8.68695 0.941273C8.55879 0.937504 8.42309 0.937504 8.29492 0.941273C7.5184 0.96389 6.8625 0.997816 6.00305 1.06567C5.14359 1.12975 4.5857 1.322 4.4877 1.96659C4.29922 3.76088 3.97881 5.76627 3.20605 6.10176H13.7758Z"
                        fill="#455B6A"
                      />
                      <path
                        d="M13.7758 19.8983C13.0068 20.2337 12.6864 22.2391 12.4942 24.0334C12.3924 24.678 11.8345 24.8703 10.9788 24.9343C10.1194 25.0022 9.46348 25.0361 8.68695 25.0587C8.55879 25.0625 8.42309 25.0625 8.29492 25.0587C7.5184 25.0361 6.8625 25.0022 6.00305 24.9343C5.14359 24.8703 4.5857 24.678 4.4877 24.0334C4.29922 22.2391 3.97881 20.2337 3.20605 19.8983H13.7758Z"
                        fill="#455B6A"
                      />
                      <path
                        d="M13.3387 19.9322H3.64723C2.15826 19.9322 0.948242 18.7259 0.948242 17.2332V8.76683C0.948242 7.27786 2.15449 6.06784 3.64723 6.06784H13.3387C14.8277 6.06784 16.0377 7.27409 16.0377 8.76683V17.237C16.0377 18.7259 14.8277 19.9322 13.3387 19.9322Z"
                        fill="#F0F3F5"
                      />
                      <path
                        d="M12.4753 18.6995H4.51029C3.2852 18.6995 2.29004 17.7044 2.29004 16.4793V9.51696C2.29004 8.29186 3.2852 7.29671 4.51029 7.29671H12.4791C13.7042 7.29671 14.6993 8.29186 14.6993 9.51696V16.4793C14.6956 17.7082 13.7004 18.6995 12.4753 18.6995Z"
                        fill="#516C72"
                      />
                      <path
                        d="M16.6973 14.1384H16.0376V11.8578H16.6973C16.8933 11.8578 17.0516 12.0162 17.0516 12.2122V13.7841C17.0516 13.9801 16.8933 14.1384 16.6973 14.1384Z"
                        fill="#5BCAEE"
                      />
                      <path
                        d="M7.5598 10.9268C7.5598 11.6392 6.98307 12.2159 6.27062 12.2159C5.55818 12.2159 4.98145 11.6392 4.98145 10.9268C4.98145 10.2143 5.55818 9.63759 6.27062 9.63759C6.98307 9.63759 7.5598 10.2143 7.5598 10.9268Z"
                        fill="#FBD984"
                      />
                      <path
                        d="M12.0041 10.9268C12.0041 11.6392 11.4274 12.2159 10.715 12.2159C10.0025 12.2159 9.42578 11.6392 9.42578 10.9268C9.42578 10.2143 10.0025 9.63759 10.715 9.63759C11.4274 9.63759 12.0041 10.2143 12.0041 10.9268Z"
                        fill="#F37970"
                      />
                      <path
                        d="M7.5598 15.0733C7.5598 15.7857 6.98307 16.3624 6.27062 16.3624C5.55818 16.3624 4.98145 15.7857 4.98145 15.0733C4.98145 14.3608 5.55818 13.7841 6.27062 13.7841C6.98307 13.7841 7.5598 14.3608 7.5598 15.0733Z"
                        fill="#5BCAEE"
                      />
                      <path
                        d="M12.0041 15.0733C12.0041 15.7857 11.4274 16.3624 10.715 16.3624C10.0025 16.3624 9.42578 15.7857 9.42578 15.0733C9.42578 14.3608 10.0025 13.7841 10.715 13.7841C11.4274 13.7841 12.0041 14.3608 12.0041 15.0733Z"
                        fill="#5FC3AB"
                      />
                    </svg>
                  </i>
                  <span>Sell Other Gadgets</span>
                  <i className="mnuarw">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.26956 5.625C5.15767 5.625 5.04567 5.58223 4.96025 5.49681L0.585246 1.12181C0.414293 0.950856 0.414293 0.674028 0.585246 0.503184C0.756199 0.332341 1.03303 0.332231 1.20387 0.503184L5.26956 4.56887L9.33525 0.503184C9.5062 0.332231 9.78303 0.332231 9.95387 0.503184C10.1247 0.674137 10.1248 0.950965 9.95387 1.12181L5.57887 5.49681C5.49345 5.58223 5.38145 5.625 5.26956 5.625Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </Link>
              <ul className="submnu">
                <li>
                  <Link href="/sell-used-othergadgets/apple">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/tv-ico.svg"
                          width={20}
                          height={20}
                          alt="Apple (Watch, TV, Homepod)"
                        /> 
                      </i>
                      <span>Apple (Watch, TV, Homepod)</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-othergadgets/amazon">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Amazon-ico.svg"
                          width={20}
                          height={20}
                          alt="Amazon (Alexa, Fire TV)"
                        />
                      </i>
                      <span>Amazon (Alexa, Fire TV)</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/sell-used-othergadgets/google">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Google-Pixel-ico.svg"
                          width={20}
                          height={20}
                          alt="Google Home"
                        />
                      </i>
                      <span>Google Home</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="https://buy.budli.in/collections/mobile-phones">
                <a className={styles.nav_item}>
                  <i className="mnusvg">
                    <svg
                      width="13"
                      height="22"
                      viewBox="0 0 13 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.1131 2.76727C12.1131 1.94026 11.4433 1.27042 10.6163 1.27042C8.46755 1.27042 4.53231 1.27042 2.38358 1.27042C1.55656 1.27042 0.886719 1.94026 0.886719 2.76727V19.2327C0.886719 20.0597 1.55656 20.7296 2.38358 20.7296H10.6163C11.4433 20.7296 12.1131 20.0597 12.1131 19.2327C12.1131 15.6081 12.1131 6.39191 12.1131 2.76727Z"
                        fill="#2DBBFF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.70613 13.6397C5.56018 13.7856 5.32293 13.7856 5.17699 13.6397C5.03104 13.4938 5.03104 13.2565 5.17699 13.1106L8.35257 9.93498C8.49851 9.78904 8.73576 9.78904 8.88171 9.93498C9.02765 10.0809 9.02765 10.3182 8.88171 10.4641L5.70613 13.6397ZM5.0258 12.2035C4.87986 12.3494 4.64261 12.3494 4.49666 12.2035C4.35072 12.0575 4.35072 11.8203 4.49666 11.6743L6.91633 9.25466C7.06228 9.10872 7.29878 9.10872 7.44547 9.25466C7.59142 9.4006 7.59142 9.63786 7.44547 9.7838L5.0258 12.2035Z"
                        fill="#8EDAFF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.00312 3.14149C4.79655 3.14149 4.62891 2.97385 4.62891 2.76728C4.62891 2.56071 4.79655 2.39307 5.00312 2.39307H7.99683C8.2034 2.39307 8.37105 2.56071 8.37105 2.76728C8.37105 2.97385 8.2034 3.14149 7.99683 3.14149H5.00312Z"
                        fill="#707070"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.886719 17.2127H8.1098C8.89191 17.2127 9.52583 16.5788 9.52583 15.7967V1.27042H10.6163C11.4433 1.27042 12.1131 1.94026 12.1131 2.76727V19.2327C12.1131 20.0597 11.4433 20.7296 10.6163 20.7296H2.38358C1.55656 20.7296 0.886719 20.0597 0.886719 19.2327V17.2127Z"
                        fill="#0AB0FF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.4875 2.76728V19.2327C12.4875 20.2663 11.6501 21.1038 10.6165 21.1038H2.38377C1.35019 21.1038 0.512695 20.2663 0.512695 19.2327V2.76728C0.512695 1.7337 1.35019 0.89621 2.38377 0.89621H10.6165C11.6501 0.89621 12.4875 1.7337 12.4875 2.76728ZM11.7391 2.76728C11.7391 2.14758 11.2362 1.64464 10.6165 1.64464H2.38377C1.76407 1.64464 1.26112 2.14758 1.26112 2.76728V19.2327C1.26112 19.8524 1.76407 20.3553 2.38377 20.3553H10.6165C11.2362 20.3553 11.7391 19.8524 11.7391 19.2327V2.76728Z"
                        fill="#707070"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.52602 0.89621H10.6165C11.6501 0.89621 12.4875 1.7337 12.4875 2.76728V19.2327C12.4875 20.2663 11.6501 21.1038 10.6165 21.1038H2.38377C1.35019 21.1038 0.512695 20.2663 0.512695 19.2327V17.2127H1.26112V19.2327C1.26112 19.8524 1.76407 20.3553 2.38377 20.3553H10.6165C11.2362 20.3553 11.7391 19.8524 11.7391 19.2327V2.76728C11.7391 2.14758 11.2362 1.64464 10.6165 1.64464H9.52602V0.89621Z"
                        fill="#595959"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.4875 2.76728V19.2327C12.4875 20.2663 11.6501 21.1038 10.6165 21.1038H2.38377C1.35019 21.1038 0.512695 20.2663 0.512695 19.2327V2.76728C0.512695 1.7337 1.35019 0.89621 2.38377 0.89621H10.6165C11.6501 0.89621 12.4875 1.7337 12.4875 2.76728ZM11.7391 2.76728C11.7391 2.14758 11.2362 1.64464 10.6165 1.64464H2.38377C1.76407 1.64464 1.26112 2.14758 1.26112 2.76728V19.2327C1.26112 19.8524 1.76407 20.3553 2.38377 20.3553H10.6165C11.2362 20.3553 11.7391 19.8524 11.7391 19.2327V2.76728ZM5.00327 3.14149C4.7967 3.14149 4.62905 2.97385 4.62905 2.76728C4.62905 2.56071 4.7967 2.39307 5.00327 2.39307H7.99698C8.20355 2.39307 8.37119 2.56071 8.37119 2.76728C8.37119 2.97385 8.20355 3.14149 7.99698 3.14149H5.00327Z"
                        fill="#272A3B"
                      />
                    </svg>
                  </i>
                  <span>Buy Renewed Mobiles</span>
                  <i className="mnuarw">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.26956 5.625C5.15767 5.625 5.04567 5.58223 4.96025 5.49681L0.585246 1.12181C0.414293 0.950856 0.414293 0.674028 0.585246 0.503184C0.756199 0.332341 1.03303 0.332231 1.20387 0.503184L5.26956 4.56887L9.33525 0.503184C9.5062 0.332231 9.78303 0.332231 9.95387 0.503184C10.1247 0.674137 10.1248 0.950965 9.95387 1.12181L5.57887 5.49681C5.49345 5.58223 5.38145 5.625 5.26956 5.625Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </Link>
              <ul className="submnu">
                <li>
                  <Link href="https://buy.budli.in/collections/iphone">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Apple-iPhone-ico.svg"
                          width={20}
                          height={20}
                          alt="Apple iPhone"
                        />
                      </i>
                      <span>Apple iPhone</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/samsung-mobile">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/samsung-ico.svg"
                          width={20}
                          height={20}
                          alt="Samsung"
                        />
                      </i>
                      <span>Samsung</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/oneplus">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Oneplus-ico.svg"
                          width={20}
                          height={20}
                          alt="Oneplus"
                        />
                      </i>
                      <span>Oneplus</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/renewed-google-pixel-mobiles">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Google-Pixel-ico.svg"
                          width={20}
                          height={20}
                          alt="Google Pixel"
                        />
                      </i>
                      <span>Google Pixel</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/refurbished-xiaomi-mobiles">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/xiaomi-ico.svg"
                          width={20}
                          height={20}
                          alt="Xiaomi / Redmi"
                        />
                      </i>
                      <span>Xiaomi / Redmi</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/motorola">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Motorola-ico.svg"
                          width={20}
                          height={20}
                          alt="Motorola"
                        />
                      </i>
                      <span>Motorola</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/refurbished-mobiles">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Apple-iPhone-ico.svg"
                          width={20}
                          height={20}
                          alt="Refurbished Mobiles"
                        />
                      </i>
                      <span>Refurbished Mobiles</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/usable-mobiles">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Apple-iPhone-ico.svg"
                          width={20}
                          height={20}
                          alt="Usable Mobiles"
                        />
                      </i>
                      <span>Usable Mobiles</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/pre-owned-mobiles">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Apple-iPhone-ico.svg"
                          width={20}
                          height={20}
                          alt="Preowned Mobiles"
                        />
                      </i>
                      <span>Preowned Mobiles</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/unboxed-mobiles">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Apple-iPhone-ico.svg"
                          width={20}
                          height={20}
                          alt="Unboxed Mobiles"
                        />
                      </i>
                      <span>Unboxed Mobiles</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/renewed-5g-mobiles">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Apple-iPhone-ico.svg"
                          width={20}
                          height={20}
                          alt="5G Mobiles"
                        />
                      </i>
                      <span>5G Mobiles</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="https://buy.budli.in/collections/laptops">
                <a className={styles.nav_item}>
                  <i className="mnusvg">
                    <svg
                      width="24"
                      height="16"
                      viewBox="0 0 24 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.2903 1.3555V13.7694H14.1001L13.76 15.0932H10.2402L9.90005 13.7694H1.70996V1.3555C1.71109 0.784386 2.17376 0.321713 2.74487 0.320588H21.255C21.8259 0.319801 22.2894 0.781967 22.2902 1.35286C22.2903 1.35376 22.2903 1.35466 22.2903 1.3555Z"
                        fill="#62667C"
                      />
                      <path
                        d="M10.1401 14.2693H1.70996V13.7694H9.90011L10.1401 14.2693Z"
                        fill="#62667C"
                      />
                      <path
                        d="M22.2905 13.7694V14.2693H13.8604L14.1003 13.7694H22.2905Z"
                        fill="#62667C"
                      />
                      <path
                        d="M21.2252 1.58644V12.7046H2.77539V1.58644C2.77539 1.47507 2.86566 1.3848 2.97703 1.3848H21.0236C21.135 1.3848 21.2252 1.47507 21.2252 1.58644Z"
                        fill="#69B0EE"
                      />
                      <path
                        d="M24 14.1045V14.4344C24 15.122 23.4426 15.6795 22.7549 15.6795H1.2451C0.557446 15.6794 0 15.122 0 14.4344V14.1045C0 13.9194 0.150062 13.7694 0.335109 13.7694H9.9L10.2401 14.4844H13.7599L14.1001 13.7694H23.6649C23.8499 13.7694 24 13.9194 24 14.1045Z"
                        fill="#525666"
                      />
                      <g opacity="0.5">
                        <path
                          d="M6.32578 12.7046H5.77148L13.9666 1.3848H14.5209L6.32578 12.7046Z"
                          fill="#EEEFEE"
                        />
                        <path
                          d="M10.0336 12.7046H7.82031L16.0154 1.3848H18.2287L10.0336 12.7046Z"
                          fill="#EEEFEE"
                        />
                      </g>
                    </svg>
                  </i>
                  <span>Buy Renewed Laptop</span>
                  <i className="mnuarw">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.26956 5.625C5.15767 5.625 5.04567 5.58223 4.96025 5.49681L0.585246 1.12181C0.414293 0.950856 0.414293 0.674028 0.585246 0.503184C0.756199 0.332341 1.03303 0.332231 1.20387 0.503184L5.26956 4.56887L9.33525 0.503184C9.5062 0.332231 9.78303 0.332231 9.95387 0.503184C10.1247 0.674137 10.1248 0.950965 9.95387 1.12181L5.57887 5.49681C5.49345 5.58223 5.38145 5.625 5.26956 5.625Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </Link>
              <ul className="submnu">
                <li>
                  <Link href="https://buy.budli.in/collections/renewed-macbooks">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/apple-ico.svg"
                          width={20}
                          height={20}
                          alt="Apple Macbook"
                        />
                      </i>
                      <span>Apple Macbook</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/hp-laptop">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/hp-ico.svg"
                          width={20}
                          height={20}
                          alt="HP Laptop"
                        />
                      </i>
                      <span>HP Laptop</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/refurbished-dell-laptops">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/dell-ico.svg"
                          width={20}
                          height={20}
                          alt="Dell Laptop"
                        />
                      </i>
                      <span>Dell Laptop</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/lenovo">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/lenovo-ico.svg"
                          width={20}
                          height={20}
                          alt="Lenovo Laptop"
                        />
                      </i>
                      <span>Lenovo Laptop</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/refurbished-laptop">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Macbook-ico.svg"
                          width={20}
                          height={20}
                          alt="Refurbished Laptops"
                        />
                      </i>
                      <span>Refurbished Laptops</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/usable-laptops">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Macbook-ico.svg"
                          width={20}
                          height={20}
                          alt="Usable Laptops"
                        />
                      </i>
                      <span>Usable Laptops</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/buy-preowned-laptops">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Macbook-ico.svg"
                          width={20}
                          height={20}
                          alt="Preowned Laptops"
                        />
                      </i>
                      <span>Preowned Laptops</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/buy-unboxed-laptops">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Macbook-ico.svg"
                          width={20}
                          height={20}
                          alt="Unboxed Laptops"
                        />
                      </i>
                      <span>Unboxed Laptops</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="https://buy.budli.in/collections/other-gadgets">
                <a className={styles.nav_item}>
                  <i className="mnusvg">
                    <svg
                      width="18"
                      height="26"
                      viewBox="0 0 18 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7758 6.10176C13.0068 5.76627 12.6864 3.76088 12.4942 1.96659C12.3924 1.322 11.8345 1.12975 10.9788 1.06567C10.1194 0.997816 9.46348 0.96389 8.68695 0.941273C8.55879 0.937504 8.42309 0.937504 8.29492 0.941273C7.5184 0.96389 6.8625 0.997816 6.00305 1.06567C5.14359 1.12975 4.5857 1.322 4.4877 1.96659C4.29922 3.76088 3.97881 5.76627 3.20605 6.10176H13.7758Z"
                        fill="#455B6A"
                      />
                      <path
                        d="M13.7758 19.8983C13.0068 20.2337 12.6864 22.2391 12.4942 24.0334C12.3924 24.678 11.8345 24.8703 10.9788 24.9343C10.1194 25.0022 9.46348 25.0361 8.68695 25.0587C8.55879 25.0625 8.42309 25.0625 8.29492 25.0587C7.5184 25.0361 6.8625 25.0022 6.00305 24.9343C5.14359 24.8703 4.5857 24.678 4.4877 24.0334C4.29922 22.2391 3.97881 20.2337 3.20605 19.8983H13.7758Z"
                        fill="#455B6A"
                      />
                      <path
                        d="M13.3387 19.9322H3.64723C2.15826 19.9322 0.948242 18.7259 0.948242 17.2332V8.76683C0.948242 7.27786 2.15449 6.06784 3.64723 6.06784H13.3387C14.8277 6.06784 16.0377 7.27409 16.0377 8.76683V17.237C16.0377 18.7259 14.8277 19.9322 13.3387 19.9322Z"
                        fill="#F0F3F5"
                      />
                      <path
                        d="M12.4753 18.6995H4.51029C3.2852 18.6995 2.29004 17.7044 2.29004 16.4793V9.51696C2.29004 8.29186 3.2852 7.29671 4.51029 7.29671H12.4791C13.7042 7.29671 14.6993 8.29186 14.6993 9.51696V16.4793C14.6956 17.7082 13.7004 18.6995 12.4753 18.6995Z"
                        fill="#516C72"
                      />
                      <path
                        d="M16.6973 14.1384H16.0376V11.8578H16.6973C16.8933 11.8578 17.0516 12.0162 17.0516 12.2122V13.7841C17.0516 13.9801 16.8933 14.1384 16.6973 14.1384Z"
                        fill="#5BCAEE"
                      />
                      <path
                        d="M7.5598 10.9268C7.5598 11.6392 6.98307 12.2159 6.27062 12.2159C5.55818 12.2159 4.98145 11.6392 4.98145 10.9268C4.98145 10.2143 5.55818 9.63759 6.27062 9.63759C6.98307 9.63759 7.5598 10.2143 7.5598 10.9268Z"
                        fill="#FBD984"
                      />
                      <path
                        d="M12.0041 10.9268C12.0041 11.6392 11.4274 12.2159 10.715 12.2159C10.0025 12.2159 9.42578 11.6392 9.42578 10.9268C9.42578 10.2143 10.0025 9.63759 10.715 9.63759C11.4274 9.63759 12.0041 10.2143 12.0041 10.9268Z"
                        fill="#F37970"
                      />
                      <path
                        d="M7.5598 15.0733C7.5598 15.7857 6.98307 16.3624 6.27062 16.3624C5.55818 16.3624 4.98145 15.7857 4.98145 15.0733C4.98145 14.3608 5.55818 13.7841 6.27062 13.7841C6.98307 13.7841 7.5598 14.3608 7.5598 15.0733Z"
                        fill="#5BCAEE"
                      />
                      <path
                        d="M12.0041 15.0733C12.0041 15.7857 11.4274 16.3624 10.715 16.3624C10.0025 16.3624 9.42578 15.7857 9.42578 15.0733C9.42578 14.3608 10.0025 13.7841 10.715 13.7841C11.4274 13.7841 12.0041 14.3608 12.0041 15.0733Z"
                        fill="#5FC3AB"
                      />
                    </svg>
                  </i>
                  <span>Buy Other Gadgets</span>
                  <i className="mnuarw">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.26956 5.625C5.15767 5.625 5.04567 5.58223 4.96025 5.49681L0.585246 1.12181C0.414293 0.950856 0.414293 0.674028 0.585246 0.503184C0.756199 0.332341 1.03303 0.332231 1.20387 0.503184L5.26956 4.56887L9.33525 0.503184C9.5062 0.332231 9.78303 0.332231 9.95387 0.503184C10.1247 0.674137 10.1248 0.950965 9.95387 1.12181L5.57887 5.49681C5.49345 5.58223 5.38145 5.625 5.26956 5.625Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </Link>
              <ul className="submnu">
                <li>
                  <Link href="https://buy.budli.in/collections/tablets">
                    <a>
                      <i className="mnusvg">
                        <svg
                          width="16"
                          height="20"
                          viewBox="0 0 16 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.9502 0.793704C14.7722 0.690622 14.5663 0.632294 14.3458 0.632294H1.65408C0.986481 0.632294 0.445312 1.17346 0.445312 1.84102V18.1589C0.445312 18.3791 0.504044 18.5855 0.606832 18.7633L1.65404 19.0701H14.3483C14.8535 19.0701 15.2631 18.6605 15.2631 18.1552V1.84102L14.9502 0.793704Z"
                            fill="#615260"
                          ></path>
                          <path
                            d="M13.7251 17.3964L12.4804 18.159H9.20852L2.2583 16.0731V2.51831L3.16485 1.84102H10.6773L13.7251 2.48256V17.3964Z"
                            fill="#FFF4D6"
                          ></path>
                          <path
                            d="M12.2304 17.5546H3.76921C2.93475 17.5546 2.2583 16.8782 2.2583 16.0437V3.95633C2.2583 3.12187 2.93475 2.44542 3.76921 2.44542H12.2304C13.0648 2.44542 13.7413 3.12187 13.7413 3.95633V16.0437C13.7413 16.8781 13.0648 17.5546 12.2304 17.5546Z"
                            fill="white"
                          ></path>
                          <path
                            d="M14.9502 0.793701V18.159C14.9502 18.4928 14.6796 18.7633 14.3459 18.7633H0.606934C0.816316 19.1231 1.20786 19.3677 1.65414 19.3677H14.3459C15.0134 19.3677 15.5546 18.8265 15.5546 18.159V1.84102C15.5546 1.39477 15.31 1.00308 14.9502 0.793701Z"
                            fill="#4E3D4D"
                          ></path>
                          <path
                            d="M2.9634 2.67797C3.01583 2.36851 3.08723 2.0881 3.16521 1.84106H1.95648C1.78958 1.84106 1.6543 1.97635 1.6543 2.14325V17.8568C1.6543 18.0237 1.78958 18.159 1.95648 18.159H9.20891C9.2541 17.9744 9.29564 17.7723 9.32927 17.5546L4.10483 15.179L2.53117 3.98466L2.9634 2.67797Z"
                            fill="#FF5F7A"
                          ></path>
                          <path
                            d="M14.0434 1.84102H10.6772C10.6063 1.92613 10.441 2.13497 10.2515 2.44527L13.5105 11.9959L13.1529 16.5023L12.5335 17.5233C12.5269 17.7313 12.5099 17.9438 12.4803 18.159H14.0434C14.2103 18.159 14.3456 18.0237 14.3456 17.8568V2.1432C14.3456 1.9763 14.2103 1.84102 14.0434 1.84102Z"
                            fill="#DFBDF0"
                          ></path>
                          <path
                            d="M6.09196 9.88883C2.97665 7.07976 2.66949 4.41831 2.96304 2.67796C2.54087 2.94593 2.2583 3.41929 2.2583 3.95633V16.0437C2.2583 16.8782 2.93475 17.5546 3.76921 17.5546L9.32887 17.5545C9.61038 15.7347 9.34307 12.8204 6.09196 9.88883Z"
                            fill="#FF7E92"
                          ></path>
                          <path
                            d="M12.2304 2.44538L10.2515 2.44524C9.44802 3.76015 8.20855 6.89804 11.8831 10.1604C13.7351 11.8046 13.1577 13.0741 12.5384 13.7637C12.1855 14.1565 12.0806 14.7107 12.2289 15.2222C12.3982 15.806 12.5624 16.6223 12.5336 17.5233C13.2244 17.3842 13.7413 16.7754 13.7413 16.0437V3.95633C13.7413 3.12187 13.0648 2.44538 12.2304 2.44538Z"
                            fill="#F2D1FF"
                          ></path>
                        </svg>
                      </i>
                      <span>Tablets</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/smartwatches">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/smart-watch-ico.svg"
                          width={20}
                          height={20}
                          alt="Smartwatch"
                        />
                      </i>
                      <span>Smartwatch</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/smart-speaker">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/smart-Speaker-ico.svg"
                          width={20}
                          height={20}
                          alt="Smart Speaker"
                        />
                      </i>
                      <span>Smart Speaker</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/smart-speaker">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Media-Streamer-ico.svg"
                          width={20}
                          height={20}
                          alt="Media Streamer"
                        />
                      </i>
                      <span>Media Streamer</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/buy-refurbished-desktops">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Desktop-ico.svg"
                          width={20}
                          height={20}
                          alt="Desktop"
                        />
                      </i>
                      <span>Desktop</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/accessories">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Accessories-ico.svg"
                          width={20}
                          height={20}
                          alt="Accessories"
                        />
                      </i>
                      <span>Accessories</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/peripherals">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/Desktop-ico.svg"
                          width={20}
                          height={20}
                          alt="Peripherals"
                        />
                      </i>
                      <span>Peripherals</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/headphones">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/headphone-ico.svg"
                          width={20}
                          height={20}
                          alt="Headphones / TWS"
                        />
                      </i>
                      <span>Headphones / TWS</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://buy.budli.in/collections/speakers">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/smart-Speaker-ico.svg"
                          width={20}
                          height={20}
                          alt="Other Speakers"
                        />
                      </i>
                      <span>Other Speakers</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="/about-us">
                <a className={styles.nav_item}>
                  <i className="mnusvg">
                    <svg
                      width="20"
                      height="15"
                      viewBox="0 0 20 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.99997 2.08458C3.99997 1.15881 3.24949 0.408325 2.32372 0.408325C1.39795 0.408325 0.647461 1.15881 0.647461 2.08458C0.647461 3.01035 1.39795 3.76084 2.32372 3.76084C3.24949 3.76084 3.99997 3.01035 3.99997 2.08458Z"
                        fill="#2B6BB2"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.44429 0.550247H18.8655C19.2791 0.550247 19.6174 0.808175 19.6174 1.12309V3.19088C19.6174 3.50579 19.2789 3.76372 18.8655 3.76372H6.44429C6.03093 3.76372 5.69238 3.50594 5.69238 3.19088V1.12309C5.69238 0.808025 6.03073 0.550247 6.44429 0.550247Z"
                        fill="#2B6BB2"
                      />
                      <path
                        d="M3.99997 7.32068C3.99997 6.39491 3.24949 5.64442 2.32372 5.64442C1.39795 5.64442 0.647461 6.39491 0.647461 7.32068C0.647461 8.24645 1.39795 8.99694 2.32372 8.99694C3.24949 8.99694 3.99997 8.24645 3.99997 7.32068Z"
                        fill="#F29C1F"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.27127 5.90602H19.0385C19.3569 5.90602 19.6174 6.16387 19.6174 6.47886V8.54665C19.6174 8.86164 19.3568 9.11949 19.0385 9.11949H6.27127C5.95295 9.11949 5.69238 8.86171 5.69238 8.54665V6.47886C5.69238 6.1638 5.95288 5.90602 6.27127 5.90602Z"
                        fill="#F29C1F"
                      />
                      <path
                        d="M3.99997 12.5567C3.99997 11.6309 3.24949 10.8804 2.32372 10.8804C1.39795 10.8804 0.647461 11.6309 0.647461 12.5567C0.647461 13.4824 1.39795 14.2329 2.32372 14.2329C3.24949 14.2329 3.99997 13.4824 3.99997 12.5567Z"
                        fill="#E04141"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.05304 11.189H19.5062C19.7744 11.189 19.9938 11.4084 19.9938 11.6766V13.4367C19.9938 13.7049 19.7744 13.9243 19.5062 13.9243H6.05304C5.78485 13.9243 5.56543 13.7049 5.56543 13.4367V11.6766C5.56543 11.4084 5.78485 11.189 6.05304 11.189Z"
                        fill="#E04141"
                      />
                    </svg>
                  </i>
                  <span>Company</span>
                  <i className="mnuarw">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.26956 5.625C5.15767 5.625 5.04567 5.58223 4.96025 5.49681L0.585246 1.12181C0.414293 0.950856 0.414293 0.674028 0.585246 0.503184C0.756199 0.332341 1.03303 0.332231 1.20387 0.503184L5.26956 4.56887L9.33525 0.503184C9.5062 0.332231 9.78303 0.332231 9.95387 0.503184C10.1247 0.674137 10.1248 0.950965 9.95387 1.12181L5.57887 5.49681C5.49345 5.58223 5.38145 5.625 5.26956 5.625Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </Link>
              <ul className="submnu">
                <li>
                  <Link href="/about-us">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/about-ico.svg"
                          width={20}
                          height={20}
                          alt="About Us"
                        />
                      </i>
                      <span>About Us</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/how-it-works">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/how-it-worl-ico.svg"
                          width={20}
                          height={20}
                          alt="How it Works"
                        />
                      </i>
                      <span>How it Works</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/why-budli">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/why-budli-ico.svg"
                          width={20}
                          height={20}
                          alt="Why Budli"
                        />
                      </i>
                      <span>Why Budli</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/faq">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/faq-ico.svg"
                          width={20}
                          height={20}
                          alt="faq"
                        />
                      </i>
                      <span>FAQ's</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/budli-for-business">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/business.svg"
                          width={20}
                          height={20}
                          alt="Budli for Business"
                        />
                      </i>
                      <span>Budli for Business</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/user-reviews">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/review-ico.svg"
                          width={20}
                          height={20}
                          alt="User Reviews"
                        />
                      </i>
                      <span>User Reviews</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/contact-us">
                    <a>
                      <i className="mnusvg">
                        <Image
                          src="/images/contact-ico.svg"
                          width={20}
                          height={20}
                          alt="Contact Us"
                        />
                      </i>
                      <span>Contact Us</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MainMenu;
