import Link from "next/link";
import Image from "next/image";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import styles from "./Logo.module.css";
export default function Logo(props) {
  const { innerPage, deviceType } = props;
  const ismobiletype = isMobile ? "mobile" : "desktop";
  const ismobile = deviceType ? deviceType : ismobiletype;
  const [screenWidth, setScreenWidth] = useState(0);
  const [isMyMobile, setIsMyMobile] = useState(false)

  useEffect(()=>{
    setScreenWidth(window.innerWidth);
  },[])

useEffect(() => {
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
useEffect(()=>{
  setIsMyMobile(screenWidth <= 991)
},[screenWidth])
  const s3_front_end_image = "https://budlisell.s3.ap-south-1.amazonaws.com/assets/themes/forntend/default/img/";
  return (
    <>
    {isMyMobile ? (
      <Link href="/" className="d-inline-flex logo">
          <a className="logo">
          <Image
                  src={`${s3_front_end_image}budli-logo-v4.svg`}
                  width={82}
                  height={47}
                  alt="budli"
                  className="h-auto"
                />
          </a>
      </Link>
      ) : (
        <Link href="/" className="d-inline-flex logo">
           <a className="logo">
            <Image
                  src={`${s3_front_end_image}budli-logo-v4.svg`}
                  width={135}
                  height={78}
                  alt="budli"
                  className="h-auto"
                />
            </a>
        </Link>
      )}

    </>
  );
}
